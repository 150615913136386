import React, { useState, useEffect, useRef } from 'react'
import styles from './CTA.module.scss'
import Button from './Button'

const CTA = ({ text }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>{text}</h2>

          <div className={styles.buttonContainer}>
            <div className={styles.buttonInnerContainer}>
              <Button href="https://app.atlas.co/login" size="xl">
                Start for free
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTA
