import React, { useState, useEffect, useRef } from "react";
import styles from "./FAQ.module.scss";
import Button from "./Button";
import "intersection-observer";

const tabs = [
  {
    title: "Can I see your art in real life?",
    content:
      "My gallery is 100% online. So no, right now we don't have a physical store. If you want more photos or a free design send us an email to lweartworks@gmail.com. ",
  },
  {
    title: "How long time does shipping usually take?",
    content: "5-10 working days from you order.",
  },
  {
    title: "How do you ship my order?",
    content:
      "My art prints are carefully rolled in silk paper, then inserted inside a protective tube. The tube is then sealed and placed inside a cardboard box. The box is sealed and shipped to you.",
  },
  {
    title: "What are the shipping costs?",
    content:
      "I offer free shipping worldwide. If you have any questions, please send an email to lweartworks@gmail.com.",
  },
  {
    title: "How are your return policies?",
    content:
      "We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.",
  },
  {
    title: "Is it a real painting or print?",
    content:
      "As you see in the product description, 'Print', which means this is printed. However, if you want to purchase an original painting, you can check some of the limited paintings under 'Originals'.",
  },
  {
    title: "Any more questions?",
    content:
      "If you have any other questions, please send an email to lweartworks@gmail.com. I am here to help.",
  },
];

const FAQ = () => {
  const [activeTab, setActiveTab] = useState(-1);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div>
            <h2>FAQ</h2>
            <p>
              Frequently asked questions. Do you have other questions? Send an
              email to my team at{" "}
              <a href="mailto:lweartworks@gmail.com">lweartworks@gmail.com</a>.
            </p>
          </div>
          <div className={styles.tabContainer}>
            <div>
              {tabs.map((tab, index) => (
                <div
                  className={`${styles.tab} ${activeTab === index + 1 &&
                    styles.nextActive}`}
                >
                  <div
                    className={`${styles.tabHeader} ${activeTab === index &&
                      styles.active}`}
                    onClick={() =>
                      activeTab !== index
                        ? handleTabClick(index)
                        : setActiveTab(-1)
                    }
                  >
                    <h3>{tab.title}</h3>
                  </div>
                  <div
                    className={`${styles.tabContent} ${activeTab === index &&
                      styles.active}`}
                  >
                    {tab.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
