import React from "react";
import styles from "./SecuredPackaging.module.scss";
import { PostCard } from "../home/BestSellers";

const SecuredPackaging = ({}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div>
            <h2>Secure packaging and shipping</h2>
            <p>
              Your artwork is carefully packaged to ensure safe transit. Prints
              are packed in custom-designed, high-quality cardboard tubes, with
              heavy-duty plastic caps to create a moisture-resistant seal. These
              tubes are tailored to each piece, ensuring a snug fit.
            </p>
            <p>
              Original paintings are packed with bubble wrap and sturdy
              cardboard for extra protection. The bubble wrap cushions the
              artwork, while the cardboard prevents bending and impact damage.
            </p>
            <p>
              Both packaging methods ensure your artwork arrives in pristine
              condition, ready to be displayed.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img src="/images/packaging.jpg" alt="Secure packaging" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuredPackaging;
